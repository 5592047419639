import React from 'react'

const UploadResults:React.FC<{uploads: any[]}> = ({uploads}) => {
    console.log('coming from', uploads)
    return (
        <div className='right__content'>
            {uploads.map((upload: any) => (
                <>
        <h3>Cannical: &nbsp; <a  className='canonical_link' href={upload?.canonical[0]}>{upload?.canonical[0]}</a>
        </h3>
        <div>
        <h3>Page Title: </h3>
          <code className='text__inner--use descriptions'>{upload?.title[0]}</code>
        </div>
        <div style={{marginTop: 2}}>
          <h3>Page Description:</h3>
        <p data-id="description" className='text__inner--use descriptions'>
          {upload?.description[0]}
        </p>
        </div>

        <div style={{marginTop: 2}}>
          <h3>Headers:</h3>
        <div className='text__inner--use descriptions'>
          <p data-id="description" >
            {upload?.h1[0]}
          </p>
           <p data-id="description" >
            {upload?.h2[0]}
          </p>
            <p data-id="description" >
            {upload?.h3[0]}
          </p>
            <p data-id="description" >
            {upload?.h4[0]}
          </p>
       <p data-id="description" >
            {upload?.h5[0]}
          </p>
        </div>
        </div>

        <h3>Links</h3>
        <div className='links__holder'> 
        {upload?.links?.map((link: string) => (
              <div className='links'>
              <div>
                <a href={link}>{link}</a>
              </div>
          </div>
        ))}
        </div>
                </>
            ))}
        </div>
    )
}

export default UploadResults