import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUpload } from '@fortawesome/free-solid-svg-icons'

type IProps = {
    handleUrlChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onUrlSubmit:(e: React.FormEvent<HTMLFormElement>) => void
    url: string
}

export const AsideLeft: React.FC<IProps> = ({handleUrlChange, url, handleFileChange, onUrlSubmit}) => {

    return (
        <aside className="left">
          <h2 className="text-lg">SERP</h2>
            <div className="left__inner">
              <div className="text-inner">
                  <h1>Welcome to SERP Scrapper</h1>
                  <p className='text__inner--use'>You often wonder why other websites are doing way better on search engines? This tool will help you understand some of the keywords that are helping them rank better.</p>
                  <h1>How to use?</h1>
                  <ul style={{marginBottom: 20}} className='text__inner--use'>
                    <li>Enter url of the website you want to scrape</li>
                    <li style={{backgroundColor: 'hsl(223, 87%, 63%)', fontWeight: 'bolder'}}>Your links must begin with either http:// or https://</li>
                    <li>You can also Upload a file containing websites you want to scrape</li>
                    <li>You will be able to view Results in the right pane!</li>
                    <li>When you upload multiple links, you will also Export searched results in a CSV file</li>
                  </ul>
              </div>
              <form onSubmit={onUrlSubmit}>
                  <input type="text" value={url} onChange={handleUrlChange} placeholder='Enter URL' arial-label="Enter url for Serp" />
                  <button className="btn btn-primary" type="submit">Search</button>
              </form>
              <h1 className='text-cener' style={{marginBottom: 10}}>Upload File containing your SERP Links</h1>
              <form action="" className='file__form'>
              <label className="file">
                 <input type="file" onChange={handleFileChange} id="file" aria-label="File browser example" />
                  <span className='text-white'>Upload file</span>
                 <span>
                    <FontAwesomeIcon icon={faUpload} />
                 </span>
              </label>
              </form>
            </div>
        </aside>
    )
}