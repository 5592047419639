import React from 'react'

const SerpResults:React.FC<{results: any}> = ({results}) => {

    return (
        <div className="right__content">
        <h3>Cannical: &nbsp; <a  className='canonical_link' href={results?.canonical[0]}>{results?.canonical[0]}</a>
        </h3>
        <div>
        <h3>Page Title: </h3>
          <code className='text__inner--use descriptions'>{results?.title[0]}</code>
        </div>
        <div style={{marginTop: 2}}>
          <h3>Page Description:</h3>
        <p data-id="description" className='text__inner--use descriptions'>
          {results?.description[0]}
        </p>
        </div>

        <div style={{marginTop: 2}}>
          <h3>Headers:</h3>
        <div className='text__inner--use descriptions'>
          <p data-id="description" >
            {results?.h1[0]}
          </p>
           <p data-id="description" >
            {results?.h2[0]}
          </p>
            <p data-id="description" >
            {results?.h3[0]}
          </p>
            <p data-id="description" >
            {results?.h4[0]}
          </p>
       <p data-id="description" >
            {results?.h5[0]}
          </p>
        </div>
        </div>

        <h3>Links</h3>
        <div className='links__holder'> 
        {results?.links?.map((link: string) => (
              <div className='links'>
              <div>
                <a href={link}>{link}</a>
              </div>
          </div>
        ))}
        </div>
      </div> 
    )
}

export default SerpResults