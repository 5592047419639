import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFileExport} from '@fortawesome/free-solid-svg-icons'
import SerpResults from './RightContent'
import UploadResults from './UploadResults'
import { CSVLink } from 'react-csv'


export const AsideRight:React.FC<{results: any, uploads: any[]}> = ({results, uploads}) => {

  
    return(
         <aside className="right">
          <h2 className="text-lg">RESULTS</h2>
         
          <CSVLink data={uploads} target="_blank" className='btn  btn-export'>
                <span>Export CSV</span>
                <FontAwesomeIcon icon={faFileExport} />
            </CSVLink>

           {!results ? null : <SerpResults results={results} /> }
          <UploadResults uploads={uploads} />  
        </aside>
    )
}

