import React from 'react'

export const Navbar = () => {
    return (
        <nav className="navbar">
            <h1 className="navbar-brand" style={{color: '#ed6b5b'}}>SERP-SCRAPER</h1>
            <ul>
                <li><a href="https://github.com/hakizimana-fred" style={{color: 'skyblue'}}>Creator: Hakizimana Fred</a></li>
            </ul>
        </nav>
    )
}