import React, { useState, useEffect } from 'react'
import './App.css';
import { Navbar } from './components/Navbar';
import { AsideLeft } from './sections/AsideLeft';
import axios, { AxiosError} from 'axios'
import { AsideRight } from './components/AsideRight';

const baseURL = 'https://serpscraper-script.onrender.com/api/v1'
const { post } = axios

function App() {

  const [url, setUrl] = useState<string>('') 
  const [searchResults, setSearchResults] = useState<any>()
  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => setUrl(e.target.value)
  const [fileLinks, setFileLinks] = useState<string[]>([])
  const [uploadResults, setuploadResults] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault() 
    const reader = new FileReader() 
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const text = e.target?.result as any 
       const fileContentArray = text.split(/\r\n|\n/)
       setFileLinks(fileContentArray)
    } 
    const file = e.target.files as any 
    reader.readAsText(file[0]) 
  }

  const onUrlSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); 

  

    if ((urlPattern.test(url) && (url.indexOf("http://") === 0 || url.indexOf("https://") === 0))){
        setLoading(true)
        try{
            const { data } = await post(`https://serpscraper-script.onrender.com/api/v1/scrape-link`, {link: url})
            const { results } = data
            console.log(results)
            setuploadResults([])
            setLoading(false)
            setSearchResults(results)
      }catch(err: any) {
          alert(err?.response?.data.error) 
    } 
  }else if (url === "") {
     alert("Please Enter URL")
  }else{
    alert("invalid URL: make sure your url includes http:// or https://")
  }
  }



  useEffect(() => {
     if(fileLinks.length > 1) {
      fetchData(fileLinks)
     }

     async function fetchData(links: string[]) {
        var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
    '(\\#[-a-z\\d_]*)?$','i'); 

        setLoading(true)
       if (Array.isArray(links)) {
          const linksArr = links.filter((link: string) => link !== '') 
          const { data } = await post(`${baseURL}/scrape-links/`, {links: linksArr})
          setSearchResults(null)
          setLoading(false)
          setuploadResults(data)
       }
     }

  }, [fileLinks])

  return (
    <>
    
   <Navbar />
    <main className='main__area'>
      <div className="container grid-2">
        <AsideLeft 
          handleUrlChange={handleUrlChange}
          url={url}
          handleFileChange={handleFileChange}
          onUrlSubmit={onUrlSubmit}
        />
         {searchResults || uploadResults.length > 0 ?  
          <AsideRight  results={searchResults} uploads={uploadResults} /> 
       : null} 
      </div>
    </main>

     {loading &&  
     <div className="circular-loader"></div>
     }
    </>
  );
}

export default App;
